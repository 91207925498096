input::placeholder,
textarea::placeholder {
  @apply text-black/50 dark:text-white/40;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  @apply text-black/50 dark:text-white/40;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  @apply text-black/40 dark:text-white/40;
}

.react-colorful {
  width: 100% !important;
}

[data-reference-hidden] {
  opacity: 0;
  pointer-events: none;
}

::-webkit-scrollbar {
  @apply w-1 h-1 bg-neutral-500/20;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-500/50 rounded-full;
}

input[type='range'] {
  @apply h-2.5 bg-neutral-200 border-0 rounded appearance-none active:bg-neutral-300 transition-all;
  @apply dark:bg-neutral-700 dark:active:bg-neutral-600;

  &::-webkit-slider-thumb {
    @apply appearance-none w-3 h-5 bg-neutral-800 rounded-full active:bg-neutral-900 active:w-4 active:h-6 transition-all;
    @apply dark:bg-neutral-100 dark:active:bg-white;
  }
}


/* ------ */


.ProseMirror {
  @apply caret-black dark:caret-white outline-0 pr-8 pl-20 py-16 z-0 lg:pl-8 lg:pr-8;

  & > * {
    @apply mx-auto max-w-4xl; /* hotpresso->original:mx-auto max-w-2xl; */
  }

  .selection {
    @apply inline;
  }

  .selection,
  *::selection {
    @apply bg-black/10 dark:bg-white/20;
  }

  & > .react-renderer {
    @apply my-12 first:mt-0 last:mb-0;
  }

  &.resize-cursor {
    @apply cursor-col-resize;
  }

  .ProseMirror-gapcursor {
    @apply relative w-full max-w-2xl mx-auto;

    &:after {
      @apply border-t-black/40 dark:border-t-white/40 w-full -top-[1.5em] max-w-2xl mx-auto left-0 right-0;
    }
  }
}

[data-theme='slash-command'] {
  width: 1000vw;
}

@import './partials/animations.css';
@import './partials/blocks.css';
@import './partials/code.css';
@import './partials/collab.css';
@import './partials/lists.css';
@import './partials/placeholder.css';
@import './partials/table.css';
@import './partials/typography.css';
