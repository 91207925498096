.ProseMirror {
  code {
    @apply caret-white text-white bg-neutral-900 rounded-sm shadow-lg font-mono;

    &::selection {
      @apply bg-white/30;
    }
  }

  pre {
    @apply caret-white bg-neutral-700 text-white rounded my-12 p-4 border border-black dark:bg-neutral-900 dark:border-neutral-800;

    *::selection {
      @apply bg-white/20;
    }

    code {
      @apply bg-inherit text-inherit p-0 shadow-none;
    }

    .hljs-comment,
    .hljs-quote {
      @apply text-neutral-400;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      @apply text-red-300;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      @apply text-orange-300;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      @apply text-lime-300;
    }

    .hljs-title,
    .hljs-section {
      @apply text-yellow-300;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      @apply text-teal-300;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
}
