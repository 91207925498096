.ProseMirror {
  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  ul,
  ol {
    @apply py-0 px-8 my-6 first:mt-0 last:mb-0;

    ul,
    ol,
    li {
      @apply my-1;
    }

    p {
      @apply mt-0 mb-1;
    }
  }

  & > ul,
  & > ol {
    @apply my-8 first:mt-0 last:mb-0;
  }

  ul[data-type='taskList'] {
    @apply list-none p-0;

    p {
      @apply m-0;
    }

    li {
      @apply flex;

      > label {
        @apply grow-0 shrink-0 flex-auto mt-0.5 mr-2 select-none;
        /* hotpresso->original:grow-0 shrink-0 flex-auto mt-0.5 mr-2 select-none; */
      }

      > div {
        @apply flex-auto;
      }

      &[data-checked='true'] {
        @apply line-through;
      }
    }
  }
}
